



















  import { SnippetCallbacks, SnippetConfig, XOn, XProvide } from '@empathyco/x-components';
  import { DeviceDetector } from '@empathyco/x-components/device';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Dictionary } from '@empathyco/x-utils';
  import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
  import { PreselectedFilters } from '@empathyco/x-components/facets';
  import currencies from './i18n/currencies';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import './design-system/tokens.scss';

  @Component({
    components: {
      DeviceDetector,
      SnippetCallbacks,
      SnippetConfigExtraParams,
      PreselectedFilters,
      Tagging,
      UrlHandler,
      XModal: () => import('./components/x-modal.vue').then(m => m.default)
    }
  })
  export default class Layer extends Vue {
    protected breakpoints: Dictionary<number> = {
      mobile: 900,
      desktop: Number.POSITIVE_INFINITY
    };
    protected isOpen = false;

    protected excludedExtraParams: Array<keyof SnippetConfig> = [
      'callbacks',
      'productId',
      'uiLang',
      'consent',
      'documentDirection',
      'filters',
      'isSpa',
      'queriesPreview'
    ];

    @XOn(['UserClickedOpenEventsModal', 'UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @XProvide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }
  }
