import { Dictionary } from '@empathyco/x-utils';

/** Types of result. */
export enum VodafoneResultsTypes {
  Info = 'info',
  ProductInfo = 'product-info',
  ProductBuy = 'product-buy',
  Offer = 'offer',
  Cart = 'cart',
  PLP = 'plp'
}

/** Tariff of result. */
export interface VodafoneTariff {
  tariffName: string;
  tariffPrice: number;
  tariffPromoPeriod: string;
  tariffPromoPrice: number;
}

/** Types of result. */
export const VODAFONE_RESULT_TYPES_MAP: Dictionary<VodafoneResultsTypes> = {
  infoCTA_textImage: VodafoneResultsTypes.Info,
  infoCTA_textOnly: VodafoneResultsTypes.Info,
  infoCTA_product: VodafoneResultsTypes.ProductInfo,
  tolCTA_product: VodafoneResultsTypes.ProductBuy,
  tolCTA_offer: VodafoneResultsTypes.Offer,
  tolCTA_offer_ATC: VodafoneResultsTypes.Cart,
  tolCTA_offer_PLP: VodafoneResultsTypes.PLP
};

declare module '@empathyco/x-types' {
  export interface Result {
    description: string;
    /** Type of result. */
    cardType: VodafoneResultsTypes[];
    /** Period of a promo. */
    promoPeriod: string;
    /** Tariff of the result. */
    tariffs?: VodafoneTariff[];
  }
}

declare module '@empathyco/x-adapter-platform' {
  export interface PlatformSearchRequest {
    section: string;
  }
}
