import { Schema } from '@empathyco/x-adapter';
import { detectResultType } from '../utils';

export const vodafoneResultSchema: Schema = {
  id: '__id',
  name: '__name',
  images: '__images',
  url: '__url',
  identifier: {
    value: '__id'
  },
  price: {
    value: '__prices.current.value',
    originalValue: ({ __prices: rawPrices }) =>
      rawPrices?.previous?.value ?? rawPrices?.current.value,
    hasDiscount: ({ __prices: rawPrices }) =>
      rawPrices?.current.value < (rawPrices?.previous?.value ?? rawPrices?.current.value),
    futureValue: ({ __prices: rawPrices }) => rawPrices?.future?.value ?? rawPrices?.current.value
  },
  description: 'description',
  promoPeriod: 'promoPeriod',
  tariffs: 'tariffs',
  type: ({ cardType }) => detectResultType(cardType)
};
