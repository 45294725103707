import { VODAFONE_RESULT_TYPES_MAP, VodafoneResultsTypes } from '../types';

/**
 * Get the type of the result.
 *
 * @param type - The result from the search response.
 * @returns The type of the result.
 */
export function detectResultType(type: string): VodafoneResultsTypes {
  return VODAFONE_RESULT_TYPES_MAP[type] || VodafoneResultsTypes.Info;
}
