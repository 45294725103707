import {
  platformAdapter,
  resultSchema,
  identifierResultsEndpointAdapter,
  searchRequestSchema,
  PlatformSearchRequest,
  identifierResultsRequestSchema,
  nextQueriesRequestSchema,
  popularSearchesRequestSchema,
  querySuggestionsRequestSchema,
  recommendationsRequestSchema,
  relatedTagsRequestSchema
} from '@empathyco/x-adapter-platform';
import { SearchRequest } from '@empathyco/x-types';
import { vodafoneRequestSchema, vodafoneResultSchema } from './schemas';

export const adapter = platformAdapter;

resultSchema.$override(vodafoneResultSchema);

searchRequestSchema.$override(vodafoneRequestSchema);

nextQueriesRequestSchema.$override<SearchRequest, Partial<PlatformSearchRequest>>(
  vodafoneRequestSchema
);
popularSearchesRequestSchema.$override(vodafoneRequestSchema);
querySuggestionsRequestSchema.$override(vodafoneRequestSchema);
recommendationsRequestSchema.$override(vodafoneRequestSchema);
relatedTagsRequestSchema.$override(vodafoneRequestSchema);
identifierResultsRequestSchema.$override(vodafoneRequestSchema);

adapter.identifierResults = identifierResultsEndpointAdapter.extends({
  endpoint:
    'https://api.{extraParams.env(.)}empathy.co/search/v1/query/{extraParams.instance}/search',
  defaultRequestOptions: {
    id: 'IdentifierResultsRequest',
    parameters: {
      internal: true
    }
  }
});
