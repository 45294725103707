import { filter, wireServiceWithoutPayload, XModuleOptions } from '@empathyco/x-components';
import { DefaultFacetsService } from '@empathyco/x-components/facets';

const wireFacetsServiceWithoutPayload = wireServiceWithoutPayload(DefaultFacetsService.instance);

const selectPreselectedFilterWire = wireFacetsServiceWithoutPayload('selectPreselectedFilters');
const selectPreselectedOnLoadWire = filter(selectPreselectedFilterWire, ({ store }) => {
  return !store.getters['x/facets/selectedFilters'].length;
});

export const facetsInstallOptions: XModuleOptions<'facets'> = {
  config: {
    filtersStrategyForRequest: 'leaves-only'
  },
  wiring: {
    FacetsQueryChanged: undefined,
    UserClearedQuery: {
      selectPreselectedFilterWire
    },
    RestorePreselectedFilters: {
      selectPreselectedFilterWire
    },
    UserOpenXProgrammatically: {
      selectPreselectedOnLoadWire
    }
  }
};
